declare var require: any
var React = require('react');
var ReactDOM = require('react-dom');
//import ReactDOM from react-dom/client;
import { Children } from 'react';
import { useState, useRef, useEffect } from 'react'
import MainWs from './layouts/workspaces/MainWs';
import InsertWs from './layouts/workspaces/InsertWs';
import { BrowserRouter, Route, HashRouter, Switch } from 'react-router-dom';

import  * as funcs from "./command.controller";
import * as toolfuncs from './service/tools';


export default function App () {

  let result = undefined;

  const [wscontents, setWsContents] = useState([]);


 
  
  /*
  Questa funzione viene chiamata quando si seleziona un comando nel menu          
  Ritorna la richiesta                                                           
  con i contenuti ricevuti       
  */
  const operationSelection = (op) => {
    // Invia un comando tramite la funzione "sendCommand" presente nell'oggetto "funcs"
    funcs.sendCommand(op).then((res) => {
      // Imposta il contenuto di "wsContents" con il valore di "res" ovvero gli oggetti tornati dalòapi previa riconfigurazione
      result = res;
    }).catch((e) => {
      // Se si verifica un'eccezione, stampa il messaggio di errore nella console
      console.log(e.message);
    });
  }

  useEffect(() => {
    setWsContents(result,[]);
  });


  /*
  Questa funzione viene chiamata dalla selezione del menu principale 
  */
  const wsSelection = (ws) => {
  console.log("workspace change was clicked");
  switch (ws) {
    case 'payment':
      setWorkspace(insertworkspace);
      break;
    case 'customer':
      setWorkspace(mainworkspace);
      break;
    default:
      setWorkspace(mainworkspace);
  }
  }

  const callbacks = {'operationscallback' : operationSelection, 'wspacecallback' : wsSelection}
  
  const mainworkspace = <MainWs appcallbacks={callbacks} appcontent={wscontents} />;
  const insertworkspace = <InsertWs appcallbacks={wsSelection}/>;
  const [workspace, setWorkspace] = useState(mainworkspace);

  //========================================================================================================


  
    
    return (    
      <div className="container-fluid vh-100 vw-100 d-flex flex-column white-textcolor black-backcolor" >
               
                 <BrowserRouter>
                 <Switch>
                 <Route path="/">
                 {mainworkspace}
                 </Route>
                 <Route path="/main">
                 <div className="position-absolute top-50 start-50 translate-middle"><button>Login with google</button></div>
                 </Route>
                 </Switch>
                 </BrowserRouter>
                
              
      </div>

    );

    return (
        <div className="container-fluid vh-100 vw-100 d-flex flex-column white-textcolor black-backcolor" >
          {mainworkspace}
        </div>
      );

  }
/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<>Welcome</>);
*/
ReactDOM.render(<App/> , document.getElementById('root'));
